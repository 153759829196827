import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../../components/seo"

// 🎨 UI
import IntroPageWrapper from "../../components/Contents/IntroPageWrapper"
import SchwerpunkteSlider from "../../components/Slider/SchwerpunkteSlider"
import SchwerpunktIllustration from "../../components/Contents/SchwerpunktIllustration"

// 🚀 Transition
import Transition from "../../components/Animation/Transition"

export default function Schwerpunkte({ data, location }) {
  const sendActionToStore = useDispatch()
  const post = data.allWpSchwerpunkt.nodes
  const settings = data.wpModeSettings.acf_schwerpunkt_settings

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state ? location.state.direction : "fade"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)
  useEffect(() => {
    if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
      setAnimationDirection(TRANSITION_INFO.direction)
      setExit(TRANSITION_INFO.shouldExit)
      sendActionToStore({
        type: `PageChangeTransition/ended`,
      })
    }
    return () => {
      setExit(true)
    }
  }, [TRANSITION_INFO, sendActionToStore])

  // TODO: top_visible, bottom_visible locationsActive ausschalten

  return (
    <>
      <SEO title={settings.textTitleMobilitatsschwerpunkt} />
      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <IntroPageWrapper
          isSlider
          rightContent={<SchwerpunktIllustration slides={post} />}
        >
          <SchwerpunkteSlider slides={post} settings={settings} />
        </IntroPageWrapper>
      </Transition>
    </>
  )
}
export const query = graphql`
  query MobilitaetsschwerpunktCardQuery($lang: String!) {
    allWpSchwerpunkt(filter: { locale: { locale: { eq: $lang } } }) {
      nodes {
        title
        slug
        uri
        acf_schwerpunkt_card {
          textSchwerpunktTeaserText
          illustrationsvideo {
            mov {
              localFile {
                publicURL
              }
            }
            webm {
              localFile {
                publicURL
              }
            }
          }
        }
        acf_schwerpunkt_content {
          colorKartenFarbe
        }
      }
    }
    wpModeSettings(
      slug: { eq: "mobilitaetsschwerpunkte" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_schwerpunkt_settings {
        textButtonToProjectgoals
        textButtonMore
        textTitleMobilitatsschwerpunkt
      }
    }
  }
`

import React, { useEffect, useState } from "react"
import { Box, Flex } from "@chakra-ui/react"
import { isMobile } from "../../tools/isMobile"
// import Test01mov from "../../images/test01.mov"
// import Test01webm from "../../images/test01.webm"

import { useSelector } from "react-redux"

function supportsHEVCAlpha() {
  if (typeof window !== `undefined`) {
    const navigator = window.navigator
    const ua = navigator.userAgent.toLowerCase()
    const hasMediaCapabilities = !!(
      navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo
    )
    const isSafari =
      ua.indexOf("safari") !== -1 &&
      !(ua.indexOf("chrome") !== -1) &&
      ua.indexOf("version/") !== -1
    return isSafari && hasMediaCapabilities
  } else {
    return true
  }
}

const getWidth = () => {
  return typeof window !== `undefined`
    ? window.innerWidth
    : "undefined" ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
}

const showAnimation = (device, orientation, width) => {
  console.log("📱 DEVICE & ORIENTATION:", device, orientation, width)
  if (device === "mobile") {
    if (width < 768) {
      return false
    }
    return true
  } else {
    return true
  }
}

const SchwerpunktIllustration = ({ slides }) => {
  const CURRENT_SLIDE = useSelector(state => state.schwerpunktSlider)
  // console.log("CURRENT SLIDE", CURRENT_SLIDE)

  // Device Orientation
  const [device, setDevice] = useState()
  const [orientation, setOrientation] = useState()

  let [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    if (typeof window !== "undefined") {
      setDevice(() => (isMobile() ? "mobile" : "computer"))
      setOrientation(
        !navigator.maxTouchPoints
          ? "desktop"
          : window.screen.availHeight > window.screen.availWidth
          ? "portrait"
          : "landscape"
      )
    }

    // timeoutId for debounce mechanism
    let timeoutId = null

    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId)
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        setDevice(() => (isMobile() ? "mobile" : "computer"))
        setOrientation(
          !navigator.maxTouchPoints
            ? "desktop"
            : window.screen.availHeight > window.screen.availWidth
            ? "portrait"
            : "landscape"
        )
        setWidth(getWidth())
      }, 1000)
    }
    // set resize listener

    if (typeof window !== `undefined`) {
      window.addEventListener("resize", resizeListener)
    }

    // clean up function
    return () => {
      // remove resize listener
      if (typeof window !== `undefined`) {
        window.removeEventListener("resize", resizeListener)
      }
    }
  }, [])

  const TEMPORARY_LINKS = [
    slides[CURRENT_SLIDE.current]?.acf_schwerpunkt_card?.illustrationsvideo?.mov
      ?.localFile?.publicURL,
    slides[CURRENT_SLIDE.current]?.acf_schwerpunkt_card?.illustrationsvideo
      ?.webm?.localFile?.publicURL,
  ]
  return (
    <Box>
      {showAnimation(device, orientation, width) ? (
        <Flex
          alignItems="flex-end"
          width={["100%", "70%", "100%", "100%"]}
          height={["488px", "488px", "477px", "477px"]}
          margin="0 auto"
          mt={["-100px", "-100px", "0px", "0px"]}
        >
          {TEMPORARY_LINKS[0] && TEMPORARY_LINKS[1] ? (
            <video
              id="player"
              muted
              autoPlay
              preload="auto"
              playsInline={true}
              src={
                supportsHEVCAlpha() ? TEMPORARY_LINKS[0] : TEMPORARY_LINKS[1]
              }
            ></video>
          ) : (
            ""
          )}
        </Flex>
      ) : (
        ""
      )}
    </Box>
  )
}

export default SchwerpunktIllustration

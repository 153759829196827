import React, { useCallback, useEffect, useState } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"

// 🎨 UI
import { Heading, Box, Text, Spacer, Flex } from "@chakra-ui/react"
import SliderButton from "../../components/Buttons/SliderButton"
import { insertStyledHTMLContent } from "../../tools/helper"

// 🚀 Transition
import TransitionButton from "../../components/Buttons/TransitionButton"
import { moveCurrentPageOut } from "../../components/Animation/PageTransition"
import { moveCameraToLayer } from "../../components/Animation/CameraAnimation"

// <-🖼 ->Slider
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import TOCSliderButton from "../Buttons/TOCSliderButton"

const SchwerpunkteSlider = props => {
  const sendActionToStore = useDispatch()

  // <-🖼 ->Slider
  const SLIDERINFO = useSelector(state => state.schwerpunktSlider, shallowEqual)
  const LANG = useSelector(state => state.currentLang)

  // * 🗺 Map

  useEffect(() => {
    moveCameraToLayer(sendActionToStore, "schwerpunkte")
  }, [sendActionToStore])

  // * Change background color

  const [currentColor, setCurrentColor] = useState(
    props.slides[0]?.acf_schwerpunkt_content?.colorKartenFarbe
  )

  useEffect(() => {
    setCurrentColor(
      props.slides[SLIDERINFO.current].acf_schwerpunkt_content.colorKartenFarbe
    )
  }, [SLIDERINFO, props.slides])

  useEffect(() => {
    sendActionToStore({
      type: `change/map/groundColor`,
      payload: currentColor,
    })
  }, [currentColor, sendActionToStore])

  return (
    <Box
      p={["24px", "32px", "32px", "64px"]}
      width="100%"
      position="relative"
      background="white"
      maxWidth="700px"
      borderRadius="30px"
      // shadow="subtle"
      className="elevation_sm"
    >
      {/* TITEL */}
      <Flex>
        <Heading as="h2" size="md" color="modeGray.500">
          {props.settings.textTitleMobilitatsschwerpunkt}
        </Heading>
        <Spacer />
        <Text fontWeight="bold">
          {SLIDERINFO.current + 1}/{props.slides.length}
        </Text>
      </Flex>

      {/* Slider */}
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={false}
        dynamicHeight={true}
        useKeyboardArrows={true}
        selectedItem={SLIDERINFO.current}
        emulateTouch={false}
        swipeable={false}
      >
        {props.slides.map((p, index) => {
          return (
            <Box key={index}>
              <Heading as="h3" align="left" size={"xl"} mt="16px" mb="32px">
                {p.title}
              </Heading>
              <Text
                className="sliderParagraphs text-left pb-3"
                dangerouslySetInnerHTML={insertStyledHTMLContent(
                  p.acf_schwerpunkt_card.textSchwerpunktTeaserText
                )}
              />
            </Box>
          )
        })}
      </Carousel>

      <SliderButton
        left
        currentSliderIndex={SLIDERINFO.current}
        slidesAmount={props.slides.length}
        channel={"schwerpunktSlider"}
        // onClick={() => rotateCamera(SLIDERINFO.current)}
      />
      <SliderButton
        right
        currentSliderIndex={SLIDERINFO.current}
        slidesAmount={props.slides.length}
        channel={"schwerpunktSlider"}
        // onClick={() => rotateCamera(SLIDERINFO.current)}
      />

      <TOCSliderButton
        sendActionToStore={sendActionToStore}
        slides={props.slides}
        currentSlide={SLIDERINFO.current}
        channel={"schwerpunktSlider"}
        title={props.settings.textTitleSchwerpunkteSlider}
      />

      {/* Buttons */}
      {/* <div className="flex absolute left-0 -bottom-4 w-full justify-center"> */}
      <Flex
        pos="absolute"
        width="100%"
        justify="center"
        left="0px"
        bottom="-28px"
      >
        <TransitionButton
          primary
          className="mr-1"
          text={props.settings?.textButtonToProjectgoals}
          link={
            props.slides[SLIDERINFO.current]?.uri +
            (LANG === "en" ? "projectgoals" : "projektziele")
          }
          targetPageInAnimation="down"
          schwerpunkt={props.slides[SLIDERINFO.current]?.title}
          onClick={() => {
            moveCameraToLayer(sendActionToStore, "projektziele")
            moveCurrentPageOut(sendActionToStore, "up")
            sendActionToStore({
              type: `projektzieleSlider/change`,
              payload: {
                current: 0,
              },
            })
          }}
        />
        <TransitionButton
          text={props.settings?.textButtonMore}
          link={props.slides[SLIDERINFO.current]?.slug}
          targetPageInAnimation="right"
          onClick={() => {
            moveCurrentPageOut(sendActionToStore, "left")
          }}
        />
      </Flex>
    </Box>
  )
}

export default SchwerpunkteSlider
